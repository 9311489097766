// src/pages/contact.js
import * as React from 'react';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import CatalogSection from '../components/CatalogSection/CatalogSection';
import CtrSection from '../components/CtrSection/CtrSection';
import TextImageSection from '../components/TextImageSection/textImageSection';
import Layout from '../components/layout';
import Seo from '../components/seo';

const CatalogPage = ({ data }) => {
  return (
    <Layout>
      <TextImageSection
        subtitle="Catalog｜カタログ"
        title="カタログ、制作実績"
        text="デジタルクリエイティブは、様々な飲食店様向けに適切なWebサイトの設計、広告運用をサポートしてまいります。"
        imageUrl="/images/haikei3.webp"
        imageAlt="ホームページ制作"
      />
      <Breadcrumb
        crumbs={[
          { pathname: '/', crumbLabel: 'TOP' },
          { pathname: '/catalog', crumbLabel: 'カタログ、実績' },
        ]}
      />
      <CatalogSection
        title="カタログ"
        subTitle="CATALOG"
        services={[
          {
            href: 'https://bar-sample.jamstack-media.com/',
            imageSrc: '/bar2024.webp',
            name: 'Barのサンプルサイト',
          },
          {
            href: 'https://italian.jamstack-media.com/',
            imageSrc: '/italian2024.webp',
            name: 'イタリアンレストランのサンプルサイト',
          },
          {
            href: 'https://yakiniku.jamstack-media.com/',
            imageSrc: '/yakiniku2024.webp',
            name: '焼肉屋のサンプルサイト',
          },
          {
            href: 'https://bakerysite.jamstack-media.com/',
            imageSrc: '/bakery2.webp',
            name: 'パン屋のサンプルサイト',
          },
          {
            href: 'https://catalog-corporatesite-001.jamstack-media.com/',
            imageSrc: '/カタログコーポレート001-2.webp',
            name: 'コーポレートのサンプルサイト',
          },
          {
            href: 'https://catalog-blog-001.jamstack-media.com/',
            imageSrc: '/catalog-blog001.webp',
            name: 'ブログページのサンプル1',
          },
          {
            href: 'https://catalog-blog-002.jamstack-media.com/',
            imageSrc: '/カタログブログ002.webp',
            name: 'ブログページのサンプル2',
          },
        ]}
      />
      <CatalogSection
        title="制作実績"
        subTitle="PRODUCTION RESULTS"
        services={[
          {
            href: 'https://niku-nagaoka.com/',
            imageSrc: '/nagaoka2024.webp',
            name: '肉のながおか様',
            info: 'コーディング、デザイン、CMS構築、SEO対策',
          },
          {
            href: 'https://wazaiya-kitakyushu.net/',
            imageSrc: '/wazai2024.webp',
            name: '団らん処和菜屋様',
            info: 'コーディング、デザイン、CMS構築',
          },
        ]}
      />

      <CtrSection />
    </Layout>
  );
};

export default CatalogPage;

export const Head = () => (
  <Seo
    title="カタログページ"
    description="デジタルクリエイティブのカタログページ、制作実績をご紹介します。飲食店でお悩みの方は、ぜひご相談ください。"
    cover="cover/cover-catalog.jpg"
  />
);
