// HeroSection.tsx
import React from 'react';
import Button from '../commons/Button/button';
import * as styles from './HeroSection.module.css';

interface HeroSectionProps {
  title: string;
  subTitle: string;
  description: string;
  buttonTitle: string;
  imageSrc: string;
  imageAlt: string;
  ctaLink: string;
}

const HeroSection: React.FC<HeroSectionProps> = ({
  title,
  subTitle,
  description,
  buttonTitle,
  imageSrc,
  imageAlt,
  ctaLink,
}) => {
  return (
    <section className={styles.topSection}>
      <div className={styles.image}>
        <img src="/images/minamiguti.webp" alt="haikei" />
      </div>
      <div className={styles.title}>
        <h1>
          <span>飲食店特化の</span>ウェブ制作
          <br />
          コスパも高い即納対応
        </h1>
      </div>
      <p className={styles.description}>
        飲食店特化のホームページ制作・SEO対策・予約システム構築
        <br />
        集客から運営までトータルサポートでお客様を成功へ導きます！
      </p>
      <p className={styles.achievement}>
        ホームページ制作後の SEO対策・保守により
        <br />
        <span>安心のアフターサポート！</span>
      </p>
      <div className={styles.buttonWrapper}>
        <Button variant="contained" to="/meeting">
          ミーティングのご予約
        </Button>
        <Button variant="gradient" to="/contact">
          お問い合わせ
        </Button>
      </div>
    </section>
  );
};

export default HeroSection;
