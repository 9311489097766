import { graphql } from 'gatsby';
import * as React from 'react';
import Accordion from '../components/Accordion/Accordion';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import Layout from '../components/layout';
import Seo from '../components/seo';
import TextImageSection from '../components/TextImageSection/textImageSection';

const FaqPage = ({ data }) => {
  return (
    <Layout>
      <TextImageSection
        subtitle="FAQ｜よくある質問"
        title="よくある質問"
        text="弊社でよくある質問についてまとめました、他にも質問があればお気軽にお問い合わせください。"
        imageUrl="/images/haikei3.webp"
        imageAlt="ホームページ制作"
      />
      <Breadcrumb
        crumbs={[
          { pathname: '/', crumbLabel: 'TOP' },
          { pathname: '/faq', crumbLabel: 'よくある質問' },
        ]}
      />
      <Accordion
        title="よくある質問"
        subTitle="FAQ"
        sectionId="accordion"
        colorType="primary"
        items={[
          {
            question: 'ホームページ制作にはどれくらいの期間がかかりますか？',
            answer: `制作内容や規模によりますが、一般的な企業サイトであれば1～2か月程度が目安です。詳細な要件をヒアリングした後、具体的なスケジュールをご提案します。`,
          },
          {
            question: 'ホームページ制作の費用はどれくらいですか？',
            answer: `基本プランは約30万円からご用意しています。カスタマイズの内容や追加機能に応じて費用が変わりますので、まずはお気軽にお見積りをご依頼ください。`,
          },
          {
            question: 'ホームページ制作のデザインの修正は何回まで可能ですか？',
            answer: `基本プランでは3回までの修正を含んでいます。それ以上の修正が必要な場合は、追加料金をご相談させていただきます。`,
          },
          {
            question: 'ホームページ公開後のサポートはありますか？',
            answer: `はい、公開後も更新作業やトラブル対応をサポートする保守プランをご用意しています。詳細はプラン内容をご確認ください。`,
          },
          {
            question: 'SEO対策の費用はどれくらいですか？',
            answer: `基本プランは月5万円からご用意しています。記事投稿、サイト構造の改善、キーワード調査と最適化を行います`,
          },
          {
            question: 'SEO対策の効果が出るまでどれくらいかかりますか？',
            answer: `通常、3～6か月程度で効果が現れることが多いですが、競合状況やキーワードの難易度によって異なります。定期的な改善を行うことで、持続的な成果を目指します。`,
          },
          {
            question: 'SEO対策は小規模なサイトにも必要ですか？',
            answer: `はい、どの規模のサイトでもSEO対策は効果的です。特に地域密着型や特定のニッチな市場をターゲットにする場合、大きな成果が期待できます。`,
          },
        ]}
      />
    </Layout>
  );
};

export default FaqPage;

export const Head = ({ data }) => {
  const companyName = data.site.siteMetadata?.companyName || `弊社`;
  const title = 'よくある質問';
  return (
    <Seo
      title={`${title}`}
      description={`${companyName}の${title}はこちら`}
      cover="cover/cover-faq.jpg"
    />
  );
};

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        companyName
        title
      }
    }
  }
`;
