import { graphql } from 'gatsby';
import * as React from 'react';
import CtrSection from '../components/CtrSection/CtrSection';
import HeroSection3 from '../components/HeroSection3/HeroSection';
import NewsSection from '../components/NewsSection/NewsSection';
import PartnershipSection from '../components/PartnershipSection/PartnershipSection';
import ReasonSection from '../components/ReasonSection/ReasonSection';
import Layout from '../components/layout';
import Seo from '../components/seo';

const CorporateIndex = ({ data, location }) => {
  // GraphQLデータからニュース項目を生成
  const newsItems = data.nanoCms.articles.edges.map(({ node }) => ({
    id: node.url, // 一意のIDが必要な場合、URLをIDとして使用
    postDate: node.updatedAt, // 投稿日
    content: node.title, // コンテンツ(タイトル)
    url: `/articles/${node.url}`, // URL
  }));

  return (
    <Layout>
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'Organization',
          name: 'デジタルクリエイティブ株式会社',
          url: 'https://digitalcreative.co.jp/',
          logo: 'https://digitalcreative.co.jp/logo006.svg',
          address: {
            '@type': 'PostalAddress',
            streetAddress: '子安町４丁目２３番８号 坂本ビル２０２号',
            addressLocality: '八王子市',
            addressRegion: '東京都',
            postalCode: '192-0904',
            addressCountry: 'JP',
          },
        })}
      </script>
      <HeroSection3 />
      <NewsSection items={newsItems} />
      <ReasonSection />
      <PartnershipSection />
      <CtrSection />
    </Layout>
  );
};

export default CorporateIndex;

export const Head = () => (
  <Seo
    description="デジタルクリエイティブ株式会社は「食の未来をデジタル技術で切り拓く」という企業理念のもと、八王子市を中心に飲食店様向けのWebサイト制作、広告運用をサポートしています。"
    title="デジタルクリエイティブ株式会社"
    cover="cover/cover-top.jpg"
  />
);

export const pageQuery = graphql`
  query {
    nanoCms {
      articles(isPublished: true, category: "お知らせ", limit: 3) {
        edges {
          node {
            title
            url
            updatedAt
          }
        }
      }
    }
  }
`;
