// src/components/ReasonSection.tsx

import React from 'react';
import ScrollAnimation from '../ScrollAnimation/ScrollAnimation';
import * as styles from './ReasonSection.module.css';

type ColorType = 'primary' | 'secondary';

type ReasonSectionProps = {
  imageSrc: string;
  imageAlt: string;
  title: string;
  description: string;
  sectionId: string;
  subTitle: string;
  colorType: ColorType;
  ctrLink: string;
  ctrText: string;
  founderName: string;
};

const ReasonSectionRight: React.FC<ReasonSectionProps> = ({
  imageSrc,
  imageAlt,
  title,
  description,
  sectionId,
  subTitle,
  colorType,
  ctrLink,
  ctrText,
  founderName,
}) => {
  let ReasonSectionClass = styles.ReasonSectionPrimary; // デフォルトのスタイル
  if (colorType === 'primary') {
    ReasonSectionClass = styles.ReasonSectionPrimary;
  } else if (colorType === 'secondary') {
    ReasonSectionClass = styles.ReasonSectionSecondary;
  }
  return (
    <ScrollAnimation>
      <section className={ReasonSectionClass} id={sectionId}>
        <div className={styles.reasons}>
          <p>REASONS</p>
          <h2>
            八王子の飲食店の
            <br />
            ホームページ制作・SEO対策で
            <br />
            選ばれる理由！
          </h2>
        </div>
        <div className={styles.reasonItems}>
          <div className={styles.reasonItem}>
            <div className={styles.reasonImageArea}>
              <img src="/corporate/t102.webp" alt="haikei" />
            </div>
            <div className={styles.reasonTextArea}>
              <div className={styles.reasonTitle}>
                <span>01</span>
                <h3>
                  高いコストパフォーマンス！
                  <br />
                  集客へ繋げるホームページ制作
                </h3>
              </div>
              <div className={styles.reasonText}>
                <p>
                  ホームページを作っただけでは集客へ繋げるのは難しいです。弊社はお店の雰囲気や魅力が伝わるようサイトデザインに工夫を凝らし、集客に繋がる魅力的なホームページを作成します。さらに検索結果が上位に表示されるよう継続的にサポートします。
                </p>
              </div>
            </div>
          </div>

          <div className={styles.reasonItem}>
            <div className={styles.reasonImageArea}>
              <img src="/corporate/t101.webp" alt="haikei" />
            </div>
            <div className={styles.reasonTextArea}>
              <div className={styles.reasonTitle}>
                <span>02</span>
                <h3>
                  集客と運営・保守で
                  <br />
                  安心のアフターサポート
                </h3>
              </div>
              <div className={styles.reasonText}>
                <p>
                  集客の為にSEO対策はとても重要です。弊社のSEO対策は中長期的な視点に立ったもので、お店の立地や料理の特徴、ターゲット層などを詳細に分析し最適なキーワードの選定やコンテンツ対策を継続的に実施します。
                </p>
              </div>
            </div>
          </div>

          <div className={styles.reasonItem}>
            <div className={styles.reasonImageArea}>
              <img src="/corporate/t103.webp" alt="haikei" />
            </div>
            <div className={styles.reasonTextArea}>
              <div className={styles.reasonTitle}>
                <span>03</span>
                <h3>
                  超高速レンダリング
                  <br />
                  快適ホームページ閲覧
                </h3>
              </div>
              <div className={styles.reasonText}>
                <p>
                  最新のウェブ技術を駆使した弊社のホームページは超高速レンダリングを実現します。これにより、画像やテキストがスムーズに表示されるのでストレスを感じることなく快適に閲覧することができます。
                </p>
              </div>
            </div>
          </div>

          <div className={styles.reasonItem}>
            <div className={styles.reasonImageArea}>
              <img src="/corporate/up_image.svg" alt="haikei" />
            </div>
            <div className={styles.reasonTextArea}>
              <div className={styles.reasonTitle}>
                <span>04</span>
                <h3>
                  お急ぎの方も安心
                  <br />
                  迅速なホームページ制作
                </h3>
              </div>
              <div className={styles.reasonText}>
                <p>
                  お店の情報を発信する「名刺代わりのホームページ」を迅速にご用意できます。
                  弊社では最短2週間でのホームページオープンが可能です。
                  開業時の費用がかさむ中、初期費用を抑えるサブスクプランから、本格的なものまで、様々なニーズにお応えします。
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ScrollAnimation>
  );
};

export default ReasonSectionRight;
